export const sectionStyle = (props) => {
	const containerHeightProp = props.containerHeight
		? {
				height: `calc(100% - ${props.containerHeight})`,
		  }
		: {
				height: "calc(100% - 20px)",
		  };

	return {
		width: "100%",
		borderTop: "1px solid #a6a8d680",
		marginTop:"20px",
		...containerHeightProp,
	};
};

export const sectionHeaderStyle = (props) => {
	return {
		margin: "0",
		width: "100%",
		fontSize: "16px",
		fontWeight: "300!important",
		lineHeight: "24px",
		padding:"20px",
		// color: `${props.theme.color.secondary}`,
		color:  "#494F73",	
		borderTop:"1px solid #CBD6E2",
	
	};
};

export const sectionContentStyle = () => {
	return {
		width: "100%",
		display: "flex",
		flexDirection: "column",
		height: "calc(100% - 20px)",
	};
};

export const mediaBtnStyle = () => {
	return {
		borderBottom: "1px solid var(--grey-50, rgba(166, 168, 214, 0.5))",
		width: "100%",
		clear: "both",
	};
};

export const buttonStyle = (state, type) => {
	const activeBtn =
		state.messagetype === type
			? {
					borderBottom:"1px solid #494F73",
					color:"#494f73",
					fontWeight: "500",
					"&::before": {
						display: "none",
					},
			  }
			: {};

	return {
		display: "inline-block",
		width: "33.33%",
		float: "left",
		fontSize: "14px",
		fontWeight: "300",
		lineHeight: "16px",
		padding: "5px",
		position: "relative",
		textAlign: "center",
		cursor: "pointer",
		color:"#494f73 ",
		...activeBtn,
		"&:before": {
			"`content`": "",
			position: "absolute",
			display: "block",
			width: "2px",
			height: "16px",
			backgroundColor: "rgba(20, 20, 20, 0.12)",
			right: "-2px",
			top: "6px",
		},
		"&:last-of-type::before": {
			display: "none",
		},
	};
};

export const mediaItemStyle = (state) => {
	const docs = state.messagetype==='file'
	
	return {
		height: docs ? "calc(100% - 45px)" : '',  
		maxHeight: "100%",  
		overflowY: "auto",  
		overflowX: "hidden",
		display: "flex",
		flexWrap: "wrap",
		alignContent:"flex-start",
		fontSize: "14px",
		padding: "10px",
		paddingBottom: "40px",
		alignItems: "flex-start",
		gap: "4px",

		"& > *": {
			minHeight: "50px",
			width: "100%",  
		},
	

		'&::-webkit-scrollbar': {
			width: '3px !important', 
		},
		'&::-webkit-scrollbar-track': {
			background: '#a6a8d640 !important', // Set the background color of the track
			marginTop: '10px', // Add some gap at the top
      		marginBottom: '10px', // Add some gap at the bottom
			marginRight: '10px', 
		},
		'&::-webkit-scrollbar-thumb': {
			backgroundColor: '#9093D8 !important', // Set the color of the scrollbar thumb
			borderRadius: '20px', // Add rounded corners to the thumb
		},
	};
};

export const itemStyle = (state, props, img, context) => {
	let videotype = state.messagetype === "video";	
	const docs = state.messagetype==='file'
	let itemTypeStyle = {};
	let bgColor = `${props.theme.backgroundColor.lightGrey}`;

	if (state.messagetype === "image") {
		itemTypeStyle = {
			"> img": {
				width: "100%",
				height: "100%",
				objectFit: "contain",
			},
		};
	} else if (state.messagetype === "video") {
		itemTypeStyle = {
			"> video": {
				width: "100%",
				height: "100%",
				objectFit: "contain",
			},
		};
	} else if (state.messagetype === "file") {
		itemTypeStyle = {
			backgroundColor: '#fff',
			borderRadius:'6px',
			width:'100%',
			border: '1px solid var(--V3-Colors-Line, #CBD6E2)',
			"> a": {
				maxWidth: "100%",
				maxHeight: "100%",
				margin: docs ? "" : "auto" ,
				display: "flex",
				wordBreak: "break-word",
				textDecoration: "none", 
				// padding: "8px",
				"&:hover, &:visited": {
					color: `${props.theme.secondaryTextColor}`,
				},
				"> i": {
					width: '60px',
					height: '66.25px',
					display: "inline-block",
					backgroundImage: `url(${img})`,
					backgroundSize: "contain",
					backgroundRepeat: "no-repeat",
					backgroundPosition: "center",
					// backgroundColor: context.theme.secondaryTextColor,
				},
				"> span": {
					fontSize: "13px",
					color: `${props.theme.secondaryTextColor}`,
					whiteSpace: "pre-wrap",
					wordWrap: "break-word",
					textAlign: "left",
					width: "calc(100% - 30px)",
					display:"flex",
					alignItems:'center',
					textDecoration: "none", 
				}
			},

		};
	}

	return {
		
			width: "calc(100% - 66.66% - 9px)",			
			height: "92px",	
			margin: docs ? "" : "3px",
			display: "flex",
			justifyContent: docs ? "start" : "center",
			alignItems: "center",
			backgroundColor: bgColor,
		...itemTypeStyle,
		"@for $i from 1 through 36": {
			"&:nth-of-type(#{$i})": {
				maxWidth: "100%",
			},
		},
	};
};


export const getGroupPrivacyStyles = () => ({
	groupPrivacyContainer: {
	  padding: "16px",
	  borderTop: "1px solid #CBD6E2",
	  fontFamily: '"Lexend Deca", sans-serif',
	  width: "100%",
	},
	privacyWrapper: {
	  display: "flex",
	  justifyContent: "space-between",
	  alignItems: "center",
	  marginBottom: "8px",
	},
	privacyToggleContainer: {
	  display: "flex",
	  alignItems: "center",
	  gap: "8px",
	},
	toggleSwitch: {
	  position: "relative",
	  width: "45px",
	  height: "24px",
	  borderRadius: "12px",
	  display: "flex",
	  alignItems: "center",
	  padding: "0 5px",
	  cursor: "pointer",
	},
	toggleCircle: {
	  width: "20px",
	  height: "20px",
	  backgroundColor: "#ffffff",
	  borderRadius: "50%",
	  display: "flex",
	  justifyContent: "center",
	  alignItems: "center",
	  boxShadow: "0px 1px 4px rgba(0, 0, 0, 0.2)",
	  transition: "transform 0.3s ease",
	},
	moveLeft: {
	  transform: "translateX(-2px)",
	},
	moveRight: {
	  transform: "translateX(19px)",
	},
	privacyDescription: {
	  display: "flex",
	  alignItems: "center",
	  gap: "5px",
	  fontSize: "12px",
	  color: "#494F73",
	},
	privacyDescText: {
	  color: "#494F73",
	  fontFamily: '"Lexend Deca"',
	  fontSize: "12px",
	  fontWeight: "300",
	  lineHeight: "18px",
	},
	privacyTitle: {
	  color: "#494F73",
	  fontFamily: '"Lexend Deca"',
	  fontSize: "14px",
	  fontWeight: "500",
	  lineHeight: "24px",
	},
	privacyStatus: {
	  fontFamily: '"Lexend Deca"',
	  fontSize: "14px",
	  fontWeight: "400",
	  lineHeight: "24px",
	},
	privacyDescriptionLink: {
	  display: "flex",
	  padding: "5px 10px",
	  alignItems: "center",
	  marginTop: "5px",
	  borderRadius: "5px",
	  background: "#F5F8FA",
	  cursor: "pointer",
	  maxWidth: "max-content",
	  position: "relative",
	},
	tooltip: {
	  position: "absolute",
	  zIndex: "10",
	  whiteSpace: "nowrap",
	  marginTop: "10px",
	  display: "flex",
	  padding: "4px 26px 6px 30px",
	  flexDirection: "column",
	  justifyContent: "center",
	  alignItems: "center",
	  borderRadius: "10px",
	  border: "1px solid #CBD6E2",
	  background: "#FFF",
	  boxShadow: "4px 4px 8px 0px rgba(0, 0, 0, 0.10)",
	  color: "#494F73",
	  fontFamily: '"Lexend Deca"',
	  fontSize: "14px",
	  fontWeight: "300",
	  lineHeight: "24px",
	},
	arrow: {
	  position: "absolute",
	  top: "-8px",
	  left: "50%",
	  transform: "translate(-50%) rotate(45deg)",
	  height: "15px",
	  width: "15px",
	  borderLeft: "1px solid #CBD6E2",
	  borderTop: "1px solid #CBD6E2",
	  backgroundColor: "#fff",
	},
  });