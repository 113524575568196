import React from "react";
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/react";
import PropTypes from "prop-types";
import { CometChat } from "@cometchat/chat-sdk-javascript";

import { CometChatContext } from "../../../util/CometChatContext";

import { SharedMediaManager } from "./controller";
import * as enums from "../../../util/enums.js";

import { theme } from "../../../resources/theme";
import Translator from "../../../resources/localization/translator";

import {
	sectionStyle,
	sectionHeaderStyle,
	sectionContentStyle,
	mediaBtnStyle,
	buttonStyle,
	mediaItemStyle,
	itemStyle,
	getGroupPrivacyStyles
} from "./style";
const styles = getGroupPrivacyStyles();


import fileIcon from "./resources/file-upload.svg";
import Newdocicon from './resources/NewDocicon.svg'
import { createPopper } from '@popperjs/core';
import { getGroupDetails, postGroupDetails } from "../../../../Apiservice.js";
import { getDataFromParams } from "../../../../getUrlParams.js";


class CometChatSharedMediaView extends React.Component {
	static contextType = CometChatContext;

	constructor(props) {
		super(props);
		this._isMounted = false;
		this.state = {
			messagetype: "image",
			messageList: [],
			fullURL: "",
			isPrivate: null,
			isPopperOpen: false,
			loading: true,
		};

		this.messageContainer = React.createRef();
		this.toggleButtonRef = React.createRef();
		this.tooltipRef = React.createRef();
		this.arrowRef = React.createRef();

		CometChat.getLoggedinUser()
			.then((user) => (this.loggedInUser = user))
			.catch((error) => {
				console.error(error);
			});
	}

	componentDidMount() {		
		this._isMounted = true;
		this.getGroupDetails()
		this.SharedMediaManager = new SharedMediaManager(
			this.context.item,
			this.context.type,
			this.state.messagetype
		);
		this.getMessages(true);
		this.SharedMediaManager.attachListeners(this.messageUpdated);
		this.popperInstance = null;

		if (this.toggleButtonRef.current && this.tooltipRef.current) {
		  this.popperInstance = createPopper(this.toggleButtonRef.current, this.tooltipRef.current, {
			placement: 'top',
			modifiers: [
			  {
				name: 'arrow',
				options: {
				  element: this.arrowRef.current,
				},
			  },
			],
		  });
		}
		document.addEventListener('click', this.handleOutsideClick);
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevState.messagetype !== this.state.messagetype) {
			this.SharedMediaManager = null;
			this.SharedMediaManager = new SharedMediaManager(
				this.context.item,
				this.context.type,
				this.state.messagetype
			);
			this.getMessages(true);
			this.SharedMediaManager.attachListeners(this.messageUpdated);
		}
	}

	componentWillUnmount() {
		this.SharedMediaManager.removeListeners();
		this.SharedMediaManager = null;
		this._isMounted = false;
		if (this.popperInstance) {
			this.popperInstance.destroy();
		  }
		  document.removeEventListener('click', this.handleOutsideClick);
	}

	togglePopper = (event) => {
		this.setState((prevState) => {
		  return { isPopperOpen: !prevState.isPopperOpen };
		});
		this.copyLink(event);
	  };
	  handleOutsideClick = (event) => {
		if (
		  this.tooltipRef.current &&
		  !this.tooltipRef.current.contains(event.target) &&
		  !this.toggleButtonRef.current.contains(event.target)
		) {
		  this.setState({ isPopperOpen: false });
		}
	  };
	//callback for listener functions
	messageUpdated = (key, message) => {
		switch (key) {
			case enums.MESSAGE_DELETED:
				this.messageDeleted(message);
				break;
			case enums.MEDIA_MESSAGE_RECEIVED:
				this.messageReceived(message);
				break;
			default:
				break;
		}
	};

	messageDeleted = (deletedMessage) => {
		const messageType = deletedMessage.data.type;
		if (
			this.context.type === CometChat.ACTION_TYPE.TYPE_GROUP &&
			deletedMessage.getReceiverType() === CometChat.RECEIVER_TYPE.GROUP &&
			deletedMessage.getReceiver().guid === this.context.item.guid &&
			messageType === this.state.messagetype
		) {
			const messageList = [...this.state.messageList];
			const filteredMessages = messageList.filter(
				(message) => message.id !== deletedMessage.id
			);
			this.setState({ messageList: filteredMessages, scrollToBottom: false });
		}
	};

	//message is received or composed & sent
	messageReceived = (message) => {
		const messageType = message.data.type;
		if (
			this.context.type === CometChat.ACTION_TYPE.TYPE_GROUP &&
			message.getReceiverType() === CometChat.RECEIVER_TYPE.GROUP &&
			message.getReceiver().guid === this.context.item.guid &&
			messageType === this.state.messagetype
		) {
			let messages = [...this.state.messageList];
			messages = messages.concat(message);
			this.setState({ messageList: messages, scrollToBottom: true });
		}
	};

	getMessages = (scrollToBottom = false) => {
		this.SharedMediaManager.fetchPreviousMessages()
			.then((messages) => {
				const messageList = [...messages, ...this.state.messageList];
				if (this._isMounted) {
					this.setState({ messageList: messageList });
					if (scrollToBottom) {
						this.scrollToBottom();
					}
				}
			})
			.catch((error) => {
				const errorCode =
					error && error.hasOwnProperty("code") ? error.code : "ERROR";
				this.context.setToastMessage("error", errorCode);
			});
	};

	scrollToBottom = () => {
		if (this.messageContainer) {
			this.messageContainer.scrollTop = this.messageContainer.scrollHeight;
		}
	};

	handleScroll = (e) => {
		const top = Math.round(e.currentTarget.scrollTop) === 0;
		if (top && this.state.messageList.length) {
			this.getMessages();
		}
	};

	mediaClickHandler = (type) => {
		if (type != this.state.messagetype) {
			this.setState({ messagetype: type, messageList: [] });	
		}
	};

	getGroupDetails = () => {		
		const groupId = this.context.item?.guid;

	
		if (!groupId) {
		  console.error('Group ID is missing');
		  return;
		}
	
		getGroupDetails(groupId)
		  .then((response) => {
			if (response && response.data) {
				
			  this.setState({
				fullURL: response.data.group_link,
				isPrivate: response.data.group_type === 'private',
				loading: false,
			  });
			} else {
			  console.error('Invalid API response:', response);
			  this.setState({ loading: false });
			}
		  })
		  .catch((error) => {
			console.error('Error fetching group details:', error);
			this.setState({ loading: false });
		  });
	  };
	
	  copyLink = async () => {
		if (this.state.fullURL) {
		  window.parent.postMessage({ action: 'copyLink', link: this.state.fullURL }, '*');  
		}
		setTimeout(() => {
		  this.setState({ isPopperOpen: false });
		}, 500);
	  };

	
	changeGroupStatus = () => {
		if (this.state.isPrivate === null) {
		  console.error('Group details not loaded yet.');
		  return;
		}
	  
		const formData = {
		  group_uid: this.context.item?.guid,
		  group_status: this.state.isPrivate ? 'public' : 'private',
		};
	  
		postGroupDetails(formData)
		  .then(() => {
			
			this.setState((prevState) => ({
			  isPrivate: !prevState.isPrivate,
			}));
		  })
		  .catch((error) => {
			console.error('Error updating group status:', error);
		  });
	  };

	  checkowner=()=>{
		const loggedInUserId = getDataFromParams('Set1')
		const ownerId = this.context?.item?.owner;
		if (loggedInUserId && ownerId === loggedInUserId) {
			return true;
		}
	  }
	  
	  togglePrivacy = () => {
		const loggedInUserId = getDataFromParams('Set1')
		const ownerId = this.context?.item?.owner;
		if (loggedInUserId && ownerId === loggedInUserId) {
		  this.changeGroupStatus();
		}
	  };
	  
	  
	  
	render() {
		const template = (message, key) => {
			if (this.state.messagetype === "image" && message?.data?.attachments) {
			  return (
				<a
				  href={message.data.attachments[0].url}
				  target="_blank"
				  rel="noopener noreferrer"
				  id={message.id}
				  key={key}
				  css={itemStyle(this.state, this.props, Newdocicon, this.context)}
          className='item item__image'
				>
				  <img
					src={message.data.attachments[0].url}
					alt={Translator.translate("SHARED_MEDIA", this.props.lang)}
				  />
				</a>
			  );
			} else if (this.state.messagetype === "video" && message?.data?.attachments) {
			  return (
				<a
				  href={message.data.attachments[0].url}
				  target="_blank"
				  rel="noopener noreferrer"
				  id={message.id}
				  key={key}
				  css={itemStyle(this.state, this.props, Newdocicon, this.context)}
				  className='item item__video'
				>
				  <video src={message.data.attachments[0].url} />
				</a>
			  );
			} else if (
        this.state.messagetype === "file" &&
        message.data.attachments
      ) {
			  return (
				<div
				  id={message.id}
				  key={key}
				  css={itemStyle(this.state, this.props, Newdocicon, this.context)}
				  className='item item__file'
				>
				  <a
					href={message.data.attachments[0].url}
					target='_blank'
					rel='noopener noreferrer'
				  >
					<i></i>
					<span className="filename">{message.data.attachments[0].name}</span>
				  </a>
				</div>
			  );
			}
		  };

		const messages = [...this.state.messageList];
		const messageList = messages.map((message, key) => {
			return template(message, key);
		});

		return (
			<div
				css={sectionStyle(this.props)}
				className='section section__sharedmedia'
			>
				{this.context?.type!=='group'&&(
					<h6 css={sectionHeaderStyle(this.props)} className='section__header highblue'>
					{Translator.translate("SHARED_MEDIA", this.props.lang)}
				</h6>
				)}
				{/* Make group public private code start  */}
				{this.context?.type==='group' && (
				<div style={styles.groupPrivacyContainer} className="groupPrivacyContainer">
  <div style={styles.privacyWrapper} className="privacyWrapper">
    <div style={styles.privacyTitle} className="privacyTitle">Group privacy</div>
    <div style={styles.privacyToggleContainer} className="privacyToggleContainer">
      <span style={{ ...styles.privacyStatus, color: this.state.isPrivate && this.checkowner()  ? "#3AA352" : "#494F73" }} className="privacyStatus">
        {this.state.isPrivate ? "Private" : "Public"}
      </span>
	  {this.checkowner() ? (
      <div
        style={{ ...styles.toggleSwitch, backgroundColor: this.state.isPrivate ? "#3AA352" : "#CBD6E2" }}
        onClick={this.togglePrivacy}
      className="toggleSwitch" >
        <div
          style={{ 
            ...styles.toggleCircle, 
            transform: this.state.isPrivate ? styles.moveRight.transform : styles.moveLeft.transform 
          }}
        className="toggleCircle">
          {this.state.isPrivate ? (
             <svg
			 xmlns="http://www.w3.org/2000/svg"
			 width="10"
			 height="12"
			 viewBox="0 0 10 12"
			 fill="none"
			 
		   >
			 <path
			   fill-rule="evenodd"
			   clip-rule="evenodd"
			   d="M2.03581 5.26562L2.00625 3.76031C1.96956 1.89506 2.73219 0.421875 4.5 0.421875L5.3715 0.42275C7.28075 0.4595 7.95544 1.89737 7.99212 3.76319L8.02169 5.26562H8.49956C8.77556 5.26562 9 5.48975 9 5.76625V11.0775C9 11.3544 8.77594 11.5781 8.49956 11.5781H1.50044C1.22444 11.5781 1 11.354 1 11.0775V5.76625C1 5.48937 1.22406 5.26562 1.50044 5.26562H2.03581ZM3.06019 5.26562H6.97625C6.97831 4.92356 6.98275 4.48094 6.97313 3.995L6.96825 3.7435C6.94238 2.43006 6.80181 1.57613 5.39363 1.54912L4.61831 1.52675C2.86056 1.49175 3.01969 3.20575 3.031 3.78L3.036 4.0315L3.06019 5.26562Z"
			   fill="#3AA352"
			   stroke="#3AA352"
			   stroke-width="0.2"
			 />
		   </svg>
          ) : (
			<svg
            xmlns="http://www.w3.org/2000/svg"
            width="12"
            height="12"
            viewBox="0 0 12 12"
            fill="none"
           
          >
            <path
              d="M9.29407 9.00754C9.14899 8.56114 8.73049 8.23192 8.23387 8.23192H7.67587V6.55792C7.67587 6.40993 7.61709 6.268 7.51244 6.16336C7.40779 6.05871 7.26587 5.99992 7.11787 5.99992H3.76987V4.88392H4.88587C5.03387 4.88392 5.1758 4.82513 5.28044 4.72049C5.38509 4.61584 5.44387 4.47391 5.44387 4.32592V3.20992H6.55987C6.85586 3.20992 7.13972 3.09234 7.34901 2.88305C7.5583 2.67376 7.67587 2.3899 7.67587 2.09392V1.86514C8.34351 2.1343 8.93598 2.56123 9.40257 3.10939C9.86915 3.65755 10.196 4.3106 10.355 5.01266C10.5141 5.71471 10.5007 6.44486 10.3159 7.14059C10.1311 7.83632 9.78051 8.47691 9.29407 9.00754ZM5.44387 10.4249C3.23977 10.1514 1.53787 8.27656 1.53787 5.99992C1.53787 5.65396 1.58252 5.31916 1.65505 5.0011L4.32787 7.67392V8.23192C4.32787 8.5279 4.44545 8.81176 4.65474 9.02105C4.86403 9.23034 5.14789 9.34792 5.44387 9.34792M6.00187 0.419922C5.2691 0.419922 4.5435 0.564253 3.8665 0.844674C3.1895 1.1251 2.57437 1.53612 2.05622 2.05427C1.00977 3.10072 0.421875 4.52001 0.421875 5.99992C0.421875 7.47983 1.00977 8.89912 2.05622 9.94558C2.57437 10.4637 3.1895 10.8747 3.8665 11.1552C4.5435 11.4356 5.2691 11.5799 6.00187 11.5799C7.48178 11.5799 8.90108 10.992 9.94753 9.94558C10.994 8.89912 11.5819 7.47983 11.5819 5.99992C11.5819 5.26715 11.4375 4.54154 11.1571 3.86455C10.8767 3.18755 10.4657 2.57242 9.94753 2.05427C9.42938 1.53612 8.81425 1.1251 8.13725 0.844674C7.46025 0.564253 6.73465 0.419922 6.00187 0.419922Z"
              fill="#494F73"
            />
          </svg>
          )}
        </div>
      </div>
	  ):(
		this.state.isPrivate ? (
			<svg
			xmlns="http://www.w3.org/2000/svg"
			width="10"
			height="12"
			viewBox="0 0 10 12"
			fill="none"
			
		  >
			<path
			  fill-rule="evenodd"
			  clip-rule="evenodd"
			  d="M2.03581 5.26562L2.00625 3.76031C1.96956 1.89506 2.73219 0.421875 4.5 0.421875L5.3715 0.42275C7.28075 0.4595 7.95544 1.89737 7.99212 3.76319L8.02169 5.26562H8.49956C8.77556 5.26562 9 5.48975 9 5.76625V11.0775C9 11.3544 8.77594 11.5781 8.49956 11.5781H1.50044C1.22444 11.5781 1 11.354 1 11.0775V5.76625C1 5.48937 1.22406 5.26562 1.50044 5.26562H2.03581ZM3.06019 5.26562H6.97625C6.97831 4.92356 6.98275 4.48094 6.97313 3.995L6.96825 3.7435C6.94238 2.43006 6.80181 1.57613 5.39363 1.54912L4.61831 1.52675C2.86056 1.49175 3.01969 3.20575 3.031 3.78L3.036 4.0315L3.06019 5.26562Z"
			  fill="#3AA352"
			  stroke="#3AA352"
			  stroke-width="0.2"
			/>
		  </svg>
		 ) : (
		   <svg
		   xmlns="http://www.w3.org/2000/svg"
		   width="12"
		   height="12"
		   viewBox="0 0 12 12"
		   fill="none"
		  
		 >
		   <path
			 d="M9.29407 9.00754C9.14899 8.56114 8.73049 8.23192 8.23387 8.23192H7.67587V6.55792C7.67587 6.40993 7.61709 6.268 7.51244 6.16336C7.40779 6.05871 7.26587 5.99992 7.11787 5.99992H3.76987V4.88392H4.88587C5.03387 4.88392 5.1758 4.82513 5.28044 4.72049C5.38509 4.61584 5.44387 4.47391 5.44387 4.32592V3.20992H6.55987C6.85586 3.20992 7.13972 3.09234 7.34901 2.88305C7.5583 2.67376 7.67587 2.3899 7.67587 2.09392V1.86514C8.34351 2.1343 8.93598 2.56123 9.40257 3.10939C9.86915 3.65755 10.196 4.3106 10.355 5.01266C10.5141 5.71471 10.5007 6.44486 10.3159 7.14059C10.1311 7.83632 9.78051 8.47691 9.29407 9.00754ZM5.44387 10.4249C3.23977 10.1514 1.53787 8.27656 1.53787 5.99992C1.53787 5.65396 1.58252 5.31916 1.65505 5.0011L4.32787 7.67392V8.23192C4.32787 8.5279 4.44545 8.81176 4.65474 9.02105C4.86403 9.23034 5.14789 9.34792 5.44387 9.34792M6.00187 0.419922C5.2691 0.419922 4.5435 0.564253 3.8665 0.844674C3.1895 1.1251 2.57437 1.53612 2.05622 2.05427C1.00977 3.10072 0.421875 4.52001 0.421875 5.99992C0.421875 7.47983 1.00977 8.89912 2.05622 9.94558C2.57437 10.4637 3.1895 10.8747 3.8665 11.1552C4.5435 11.4356 5.2691 11.5799 6.00187 11.5799C7.48178 11.5799 8.90108 10.992 9.94753 9.94558C10.994 8.89912 11.5819 7.47983 11.5819 5.99992C11.5819 5.26715 11.4375 4.54154 11.1571 3.86455C10.8767 3.18755 10.4657 2.57242 9.94753 2.05427C9.42938 1.53612 8.81425 1.1251 8.13725 0.844674C7.46025 0.564253 6.73465 0.419922 6.00187 0.419922Z"
			 fill="#494F73"
		   />
		 </svg>
		 )
	  )}

    </div>
  </div>
  <div style={styles.privacyDescription} className="privacyDescription">

  <span>
      {this.state.isPrivate ? (
       <svg
	   xmlns="http://www.w3.org/2000/svg"
	   width="10"
	   height="12"
	   viewBox="0 0 10 12"
	   fill="none"
	   
	 >
	   <path
		 fill-rule="evenodd"
		 clip-rule="evenodd"
		 d="M2.03581 5.26562L2.00625 3.76031C1.96956 1.89506 2.73219 0.421875 4.5 0.421875L5.3715 0.42275C7.28075 0.4595 7.95544 1.89737 7.99212 3.76319L8.02169 5.26562H8.49956C8.77556 5.26562 9 5.48975 9 5.76625V11.0775C9 11.3544 8.77594 11.5781 8.49956 11.5781H1.50044C1.22444 11.5781 1 11.354 1 11.0775V5.76625C1 5.48937 1.22406 5.26562 1.50044 5.26562H2.03581ZM3.06019 5.26562H6.97625C6.97831 4.92356 6.98275 4.48094 6.97313 3.995L6.96825 3.7435C6.94238 2.43006 6.80181 1.57613 5.39363 1.54912L4.61831 1.52675C2.86056 1.49175 3.01969 3.20575 3.031 3.78L3.036 4.0315L3.06019 5.26562Z"
		 fill="#494F73"
		 stroke="#494F73"
		 stroke-width="0.2"
	   />
	 </svg>
      ) : (
        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none" 
		style={{verticalAlign:'baseline', paddingTop :'1px'}}
		>
          <path
            d="M9.29407 9.00754C9.14899 8.56114 8.73049 8.23192 8.23387 8.23192H7.67587V6.55792C7.67587 6.40993 7.61709 6.268 7.51244 6.16336C7.40779 6.05871 7.26587 5.99992 7.11787 5.99992H3.76987V4.88392H4.88587C5.03387 4.88392 5.1758 4.82513 5.28044 4.72049C5.38509 4.61584 5.44387 4.47391 5.44387 4.32592V3.20992H6.55987C6.85586 3.20992 7.13972 3.09234 7.34901 2.88305C7.5583 2.67376 7.67587 2.3899 7.67587 2.09392V1.86514C8.34351 2.1343 8.93598 2.56123 9.40257 3.10939C9.86915 3.65755 10.196 4.3106 10.355 5.01266C10.5141 5.71471 10.5007 6.44486 10.3159 7.14059C10.1311 7.83632 9.78051 8.47691 9.29407 9.00754ZM5.44387 10.4249C3.23977 10.1514 1.53787 8.27656 1.53787 5.99992C1.53787 5.65396 1.58252 5.31916 1.65505 5.0011L4.32787 7.67392V8.23192C4.32787 8.5279 4.44545 8.81176 4.65474 9.02105C4.86403 9.23034 5.14789 9.34792 5.44387 9.34792M6.00187 0.419922C5.2691 0.419922 4.5435 0.564253 3.8665 0.844674C3.1895 1.1251 2.57437 1.53612 2.05622 2.05427C1.00977 3.10072 0.421875 4.52001 0.421875 5.99992C0.421875 7.47983 1.00977 8.89912 2.05622 9.94558C2.57437 10.4637 3.1895 10.8747 3.8665 11.1552C4.5435 11.4356 5.2691 11.5799 6.00187 11.5799C7.48178 11.5799 8.90108 10.992 9.94753 9.94558C10.994 8.89912 11.5819 7.47983 11.5819 5.99992C11.5819 5.26715 11.4375 4.54154 11.1571 3.86455C10.8767 3.18755 10.4657 2.57242 9.94753 2.05427C9.42938 1.53612 8.81425 1.1251 8.13725 0.844674C7.46025 0.564253 6.73465 0.419922 6.00187 0.419922Z"
            fill="#494F73"
          />
        </svg>
      )}
    </span>
	
    <span style={styles.privacyDescText} className="privacyDescText">
      {this.state.isPrivate ? "Only group admins can add or invite members" : "Anyone with group link can join"}
    </span>
  </div>
  {!this.state.isPrivate && this.context?.item?.scope==='admin' &&  (
  <div className="privacy-description-link" css={styles.privacyDescriptionLink}  ref={this.toggleButtonRef}
  onClick={this.togglePopper}>
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none" style={{ marginRight: '5px' }}>
      <path d="M5.37548 10.6261L10.6259 5.37563M7.12562 2.75041L7.53078 2.28137C8.35143 1.46084 9.46442 0.999918 10.6249 1C11.7854 1.00008 12.8983 1.46116 13.7188 2.28181C14.5394 3.10246 15.0003 4.21545 15.0002 5.37594C15.0001 6.53643 14.5391 7.64935 13.7184 8.46988L13.2511 8.87592M8.87577 13.2513L8.52836 13.7186C7.69814 14.5395 6.57764 15 5.41005 15C4.24245 15 3.12195 14.5395 2.29173 13.7186C1.88251 13.3139 1.55763 12.8321 1.3359 12.3011C1.11417 11.77 1 11.2002 1 10.6247C1 10.0493 1.11417 9.47948 1.3359 8.94842C1.55763 8.41736 1.88251 7.93556 2.29173 7.53093L2.75027 7.12577" stroke="#494F73" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
    <span className="privacy-desc-text" css={styles.privacyDescText}>
      Invite to group via link
    </span>
  </div>
)}
        {this.state.isPopperOpen && (
          <div
            id="tooltip"
            role="tooltip"
            ref={this.tooltipRef}
            style={{ visibility: this.state.isPopperOpen ? 'visible' : 'hidden' }}
          css={styles.tooltip}>
            Group link copied
            <div id="arrow" ref={this.arrowRef} data-popper-arrow  css={styles.arrow}></div>
          </div>
        )}

</div>
				)}
				{/* Make group public private code start end */}

				
				<div
					css={sectionContentStyle(this.props)}
					data-id='sharedmedia'
					className='section__content'
				>
					<div css={mediaBtnStyle()} className='media__button'>
						<span
							css={buttonStyle(this.state, "image")}
							onClick={() => this.mediaClickHandler("image")}
						>
							{Translator.translate("PHOTOS", this.props.lang)}
						</span>
						<span
							css={buttonStyle(this.state, "video")}
							onClick={() => this.mediaClickHandler("video")}
						>
							{Translator.translate("VIDEOS", this.props.lang)}
						</span>
						<span
							css={buttonStyle(this.state, "file")}
							onClick={() => this.mediaClickHandler("file")}
						>
							{Translator.translate("Documents", this.props.lang)}
						</span>
					</div>
					<div
						css={mediaItemStyle(this.state)}
						className={`media_items  ${messageList.length === 0 ? 'not-found' : ''}`}
						ref={(el) => (this.messageContainer = el)}
						onScroll={this.handleScroll}
						tabIndex={0}
					>
						{messageList.length
							? messageList
							: Translator.translate("", this.props.lang)}
					</div>
				</div>
			</div>
		);
	}
}

// Specifies the default values for props:
CometChatSharedMediaView.defaultProps = {
	lang: Translator.getDefaultLanguage(),
	theme: theme,
};

CometChatSharedMediaView.propTypes = {
	lang: PropTypes.string,
	theme: PropTypes.object,
};

export { CometChatSharedMediaView };
